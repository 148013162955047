<ng-container *transloco="let t">
    <div class="max-w-4xl p-6 bg-white rounded-lg shadow-lg">
        <mat-dialog-header>
            <div class="flex justify-between items-center">
                <h1 mat-dialog-title class="text-lg font-semibold">{{t('gallery')}}</h1>
                <button mat-icon-button [matTooltip]="'Close'" (click)="_matDialogRef.close()">
                    <mat-icon class="text-gray-600" [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>
        </mat-dialog-header>

        <mat-dialog-content class="mat-typography overflow-hidden">
            <div *ngIf="gallery?.length || imageUrl?.length" class="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6">
                <div
                    *ngFor="let item of gallery; let ind = index"
                    class="relative group"
                    cdkDropList
                    (cdkDropListEntered)="onDropListEntered($event)"
                    (cdkDropListDropped)="onDropListDropped()"
                >
                    <div cdkDrag class="relative">
                        <button
                            class="absolute top-2 right-2 bg-white p-1 rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity"
                            (click)="openConfirmDialog(item, ind)">
                            <mat-icon class="text-red-500">delete_forever</mat-icon>
                        </button>
                        <img class="w-full h-32 object-cover rounded-md shadow" [src]="imagePath + item?.thumbnail?.url" alt="">
                    </div>
                </div>

                <div
                    *ngFor="let url of imageUrl; let ind = index"
                    class="relative group"
                    cdkDropList
                    (cdkDropListEntered)="onDropListEntered($event)"
                    (cdkDropListDropped)="onDropListDropped()"
                >
                    <div cdkDrag class="relative">
                        <button
                            class="absolute top-2 right-2 bg-white p-1 rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity"
                            (click)="removeImage(url, ind)">
                            <mat-icon class="text-red-500">delete_forever</mat-icon>
                        </button>
                        <img class="w-full h-32 object-cover rounded-md shadow" [src]="url" alt="">
                    </div>
                </div>
            </div>

            <fuse-alert
                class="mt-6"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type">
                {{alert.message}}
            </fuse-alert>
        </mat-dialog-content>
        <div class="flex justify-between items-center mt-6">
            <input #fileInput id="fileUpload" type="file" name="file" multiple hidden (change)="onFileChange($event.target.files)">
            <label for="fileUpload">
                <button class="fuse-mat-button-large" (click)="fileInput.click()" mat-flat-button color="primary">
                    <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                    {{t('chooseImage')}}
                </button>
            </label>

            <button class="fuse-mat-button-large" (click)="uploadGallery()"
                    mat-flat-button color="primary"
                    [disabled]="!formData || (gallery?.length + imageUrl?.length) >= 10">
                {{t('upload')}}
            </button>
        </div>
    </div>
</ng-container>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, ReplaySubject, switchMap, throwError} from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import {appConfig} from "../config/app.config";
import {
    CreateEditSupplierReqModel,
    CreateEditSupplierResModel,
    SupplierAuthReqModel
} from "../interfaces/supplier-types";
import {ApiResultModel} from "../interfaces/api-res.interface";
import {SupplierVerifyStepsModel} from "../../modules/auth/confirmation-required/confirmation-required.component";
import {Router} from "@angular/router";

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    onPassData: ReplaySubject<SupplierVerifyStepsModel> = new ReplaySubject();

    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _router: Router
    )
    {
    }

    set accessToken(token: string)
    {
        console.log('accessToken = ',token)
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${appConfig.baseUrl}/auth/supplier-login`, credentials).pipe(
            switchMap((response: any) => {
                this.accessToken = response.payload.token.accessToken;
                this._authenticated = true;

                this._userService.user = response.payload.user;

                return of(response);
            })
        );
    }

    signInUsingToken(): Observable<any>
    {
        return this._httpClient.post('api/auth/token-refresh', {
            refreshToken: this.accessToken
        }).pipe(
            catchError(() =>

                of(false)
            ),
            switchMap((response: any) => {

                if ( response.payload )
                {
                    this.accessToken = response.payload.token.accessToken;
                    this._userService.user = response.payload.user;
                }

                this._authenticated = true;

                return of(true);
            })
        );
    }

    signOut(): Observable<any>
    {
        localStorage.removeItem('accessToken');

        this._authenticated = false;

        return of(true);
    }

    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    supplierRegistration(params: SupplierAuthReqModel): Observable<ApiResultModel<{duration: number, message: string}>> {
        return this._httpClient.post<ApiResultModel<{duration: number, message: string}>>('api/auth/supplier-registration', params);
    }

    supplierVerifyAccount(params: SupplierAuthReqModel): Observable<ApiResultModel<any>> {
        return this._httpClient.post<ApiResultModel<any>>('api/auth/confirm-email-token', params);
    }

    createPassword(params: SupplierAuthReqModel): Observable<ApiResultModel<any>> {
        return this._httpClient.post<ApiResultModel<any>>('api/users/create-supplier-user', params);
    }

    supplierForgotPassword(params: SupplierAuthReqModel): Observable<ApiResultModel<any>> {
        return this._httpClient.post<ApiResultModel<any>>('api/auth/supplier-forgot-password', params);
    }

    resetSupplierPassword(params: SupplierAuthReqModel): Observable<ApiResultModel<any>> {
        return this._httpClient.post<ApiResultModel<any>>('api/auth/supplier-reset-password', params);
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    check(): Observable<boolean>
    {
        if ( this._authenticated )
        {
            return of(true);
        }

        if ( !this.accessToken )
        {
            return of(false);
        }

        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        return this.signInUsingToken();
    }

    createSupplierAccount(data: CreateEditSupplierReqModel): Observable<ApiResultModel<CreateEditSupplierResModel>> {
        return this._httpClient.post<ApiResultModel<CreateEditSupplierResModel>>(`api/suppliers/create-supplier-account`, data);
    }
}
